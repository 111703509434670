.home {
    position: relative;
    background-image: url("../../assets/homeBg.jpg");
    background-attachment: fixed;
    background-size: cover;
    display: flex;
    
    align-items: center; /* Centers content vertically */
    justify-content: center; /* Centers content horizontally */
}

.home::before {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity (0.5) as needed */
    pointer-events: none; /* Ensures the overlay doesn't interfere with any interaction */
}
