.cat_single_box {
    overflow: hidden;
    border: 1px solid #ddd;
    
    background-color: #fff;
    transition: transform 0.3s ease-in-out;
  }
  
  .cat_single_box:hover {
    transform: scale(1.05);
  }
  
  .img_holder {
    position: relative;
    width: 100%;
    height: 200px;  /* Adjust the height as needed */
  }
  
  .img_holder img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  
  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }
  
  .overlay .border {
    border: 2px solid #fff;
    padding: 5px;
  }
  
  .cat_single_box:hover .overlay {
    opacity: 1;
  }
  