.Qlinks li{
    position: relative;
    text-decoration: none;
    
    transition: color 0.5s;
    
  }
  
  .navbar-links{

    color: white;
  }
  .navbar-links:hover{

    color: rgb(19, 19, 19)
  }
  .Qlinks li::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 2px; /* Adjust thickness of the underline */
    bottom: 0;
    left: 0;
    background-color: transparent; /* Initial color of the underline */
    transition: background-color 1.3s, width 1s;
  }
  
  .Qlinks li:hover {
    color: white /* Color of the link when hovered */
  }
  

.footer{
  /* background-image: url('../../assets/footerBg.jpg'); */
  background-size: cover;
  background-position: center;
  opacity: 0.9;

}

.miniServices li{
  color: white;
}

.miniServices li:hover {
  color: rgb(10, 10, 10);
}


.address{
  column-width: 30%;
}


.footerMap{
  margin-top: -10%;
    
}

.mapFooter {
  width: 100%; /* Adjust width as needed */
  height: 500px; /* Adjust height as needed */
}



